import { module as modular } from 'modujs';

export default class extends modular {
    constructor(m) {
        super(m);

        this.events = {
          focusin: {
            input: 'active',
          },
          focusout: {
            input: 'desactive',
          },
          change: {
            checked: 'checked',
          }
        }
    }

    init() {
      
    }

    active(e) {
      const target = e.currentTarget;
      const item = this.parent('item', target);
      item.classList.add('is-active');
    }

    desactive(e) {
      const target = e.currentTarget;
      const item = this.parent('item', target);
      if(target.value == "") {
        item.classList.remove('is-active');
      }
    }

    checked(e){
      const target = e.currentTarget;
      const item = target.closest('ul');
      const items = item.children[1].childNodes;
      let check = false;

      items.forEach(its => {
        if (its.children[0].children[0].checked){
          check = true;
        } 
      });

      if(check == false){
        item.classList.remove('is-checked')
      } else{
        item.classList.add('is-checked')
      }
    }
}
