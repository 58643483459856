import { module as modular } from 'modujs';

export default class extends modular {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                btn: 'togglefilters',
                reset: 'reset',
                select: 'select',
                open: 'toggleSidebar',
                resetall: 'resetall',
                refresh: 'refresh',
                pagination: 'pagination',
                changeMonth: 'refreshMonth',
                reset: 'reset',
                checked: 'checked',
            },
            change: {
              change: 'refresh',
            },
        };

        this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
    }

    init() {
      if(this.getData('init')) {
        setTimeout(() => {
          this.refresh();
        }, 500);
      }
    }

    togglefilters(e) {
      const target = e.currentTarget;
      const item = this.parent('item', target);
      if (item.classList.contains('is-active')) {
        item.classList.remove('is-active');
      } else {
        const items = this.$('item');
        items.forEach(element => element.classList.remove('is-active')); // close section opened
        item.classList.add('is-active');
      }
    }

    select(e) {
      const target = e.currentTarget;
      const item = this.parent('item', target);

      if($(target).find('input').is(':checked')) {
        $(item).find('.-filtre .title').text($(target).find('input').val());
        $(item).find('.-filtre').addClass('is-active');
      }
    }

    reset(e) {
      const target = e.currentTarget;
      const item = this.parent('item', target);
      $(item).find('input').prop('checked', false);
      $(item).find('.-filtre .title').text($(item).find('.-filtre').attr('data-title'));
    }

    resetall(e) {
      $('input').prop('checked', false);
    }

    toggleSidebar(e) {
      const target = e.currentTarget;
      const side = document.getElementsByClassName(this.getData('side'))[0];
      if (side.classList.contains('is-active')) {
        side.classList.remove('is-active');
      } else {
        const items = this.$('side');
        items.forEach(element => element.classList.remove('is-active')); // close section opened
        side.classList.add('is-active');
      }
    }

    refresh() {
        let form = $(this.el);
        let t = this;
        jQuery.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: form.serialize(),
            success: function( response ){
                if($('.c-productList_content_products').length) {
                    $('.c-productList_content_products').html(response.content);
                }
                if($('.b-relation_pagination').length) {
                    $('.b-relation_pagination').html(response.pagination);
                }
                if($('.b-produits-listing_counter').length && response.count) {
                    $('.b-produits-listing_counter').html(response.count);
                }
                // if($('.b-relation_filtres_sidebar').length) {
                //     $('.b-relation_filtres_sidebar').removeClass('is-active');
                // }
                setTimeout(() => {
                  t.call('update', false, 'Scroll'); // update Locomotive Scroll
                  t.call('scrollTo', '.c-productList_content', 'Scroll');
                }, 500);
            },
        });
    }

    pagination(e) {
        const target = e.currentTarget;
        let paged = target.getAttribute('data-paged');
        let form = $(target).parents('.c-productList').find('form');
        let data = form.serialize()+'&paged='+paged;
        
        jQuery.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: data,
            success: function( response ){
                
                if($('.c-productList_content_products').length) {
                    $('.c-productList_content_products').html(response.content);
                }
                if($('.b-relation_pagination').length) {
                    $('.b-relation_pagination').html(response.pagination);
                }
                if($('.b-produits-listing_counter').length && response.count) {
                  $('.b-produits-listing_counter').html(response.count);
                }
                // if($('.b-relation_filtres_sidebar').length) {
                //     $('.b-relation_filtres_sidebar').removeClass('is-active');
                // }
            },
        });
        setTimeout(() => {
            this.call('update', false, 'Scroll'); // update Locomotive Scroll
            this.call('scrollTo', '.c-productList_content', 'Scroll');
        }, 500);
    }

    reset(e) {
      const target = e.currentTarget;
      let form = $(target).parents('form');
      form[0].reset();
      this.refresh(e);
      // jQuery.ajax({
      //   url: this.ajaxurl,
      //   type: 'POST',
      //   data: {'action': 'reset_filters'},
      //   success: function( response ){
      //       form[0].reset();
      //   },
      // });
    }

    checked(e) {
      const target = e.currentTarget;
      const item = target.closest('label');
      const items = item.closest('form').children;
      let check = false;

      for (let i = 0; i < 4; i++){
        if (items[i].classList.contains('-checked')){
          items[i].classList.remove('-checked');
          check == true;
        }
      }

      if(check !== true){
        item.classList.add('-checked');
      }
    }
}
