import { module as modular } from 'modujs';

export default class extends modular {
    constructor(m) {
        super(m);

        this.events = {
          click: {
            //refresh: 'refresh',
            galerie: 'toggleImage',
          }
        }

        //this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
    }

    init() {
        const img = document.querySelector('.c-product_left_gallery figure');
        img.classList.add('img-active')
    }

    /*refresh(e) {
      const target = e.currentTarget;
      const id = target.getAttribute('data-id');
      var container = document.querySelector('.b-produits-listing_produits');
      var counter = document.querySelector('.b-produits-listing_counter');
      jQuery.ajax({
        url: this.ajaxurl,
        type: 'POST',
        data: {'action': 'refresh_produits', 'term_id': id},
        success: function( response ){
          container.innerHTML = response.content;
          counter.innerHTML = response.count;
        },
      });

      this.call('update', false, 'Scroll'); // update Locomotive Scroll
    }*/

    toggleImage(e) {
      const currentTarget = e.currentTarget;
      const src = currentTarget.getAttribute('data-image-toggle');
      const alt = currentTarget.getAttribute('alt');
      const target = document.querySelector('.c-product_galerie_wrap img');
      target.setAttribute('src', src);
      target.setAttribute('alt', alt);
      document.querySelector('.img-active').classList.remove('img-active');
      currentTarget.parentNode.classList.add('img-active');
    }
}
