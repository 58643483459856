import { module as modular } from 'modujs';

export default class extends modular {
    constructor(m) {
        super(m);

        this.events = {
          click: {
            header: 'toggleSection',
            dropdown: 'toggleDrop'
          }
        }
    }

    init() {
      if (this.getData('open')) {
        this.$('section')[0].classList.add('is-open');
      }
    }

    toggleSection(e) {
      const target = e.currentTarget;
      const section = this.parent('section', target);
      if (section.classList.contains('is-open')) {
        section.classList.remove('is-open');
      } else {
        const sections = this.$('section');
        sections.forEach(element => element.classList.remove('is-open')); // close section opened
        section.classList.add('is-open');
        if(this.getData('scrollto') == 'true') {
          this.call('scrollTo', section, 'Scroll'); // scrollTo section
        }
      }
      this.call('update', false, 'Scroll'); // update Locomotive Scroll
    }

    toggleDrop(e){
      const target = e.currentTarget.parentNode;
      if (target.classList.contains('is-open')) {
        target.classList.remove('is-open');
      } else {
        target.classList.add('is-open');
        if(this.getData('scrollto') == 'true') {
          this.call('scrollTo', ul, 'Scroll'); // scrollTo section
        }
      }
      this.call('update', false, 'Scroll'); // update Locomotive Scroll
    }
}
