import { module as modular } from 'modujs';
import LocomotiveScroll from 'locomotive-scroll';

export default class extends modular {
    constructor(m) {
        super(m);
    }

    init() {
        this.scroll = new LocomotiveScroll({
            el: this.el,
            smooth: true,
            reloadOnContextChange: true,
            tablet: {
              smooth: true,
              multiplier: 10,
            },
            smartphone: {
              smooth: true,
              multiplier: 10,
            }
        });

        if($(document).outerWidth() < 501) {
          $('.b-relation-pages_wrap .c-card').attr('data-scroll-speed', 0);
        }

        $(window).resize(function(){
          if($(document).outerWidth() < 1201) {
            $('.c-product_left').attr('data-scroll-sticky', 0);
            $('.c-product_left').attr('data-scroll-target', 0);
          }
        });

        if($(document).outerWidth() < 1201) {
          $('.c-product_left').attr('data-scroll-sticky', 0);
          $('.c-product_left').attr('data-scroll-target', 0);
        }

        setTimeout(() => {
          this.scroll.update();
        }, 500);

        this.scroll.on('call', (func, way, obj, id) => {
            // Using modularJS
            this.call(func[0], { way, obj }, func[1], func[2]);
        });

        this.scroll.on('scroll', (args) => {
          // console.log(args.scroll);
          
        })
        
        // let scroll = this.scroll;
        // $('.c-product_right_container').hover(function() {
        //   $(this).scroll(function() {
        //     if($(window).scrollTop() >= $(this).offset().top + $(this).outerHeight() - window.innerHeight) {
        //       console.log('element end');
        //       scroll.start();  
        //     }else{
        //       scroll.stop();
        //     }
        //   });
        // }, function() {
        //   scroll.start();
        // });
    }

    toggleLazy(args) {
        let src = this.getData('lazy', args.obj.el)
        if (src.length) {
            if (args.obj.el.tagName === 'IMG') {
                args.obj.el.src = src
            } else {
                args.obj.el.style.backgroundImage = `url('${src}')`
            }
            this.setData('lazy', '', args.obj.el)
        }
    }

    destroy() {
        this.scroll.destroy();
    }

    scrollTo(target) {
      this.scroll.scrollTo(target, {'offset': -150});
    }

    update() {
      this.scroll.update();
    }
}
